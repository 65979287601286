import React, { useEffect, useState } from 'react'
import "./AuthPage.css"
import { isMobile } from 'react-device-detect'

function AuthPage() {

    useEffect(()=>{
        // scrollTop()
    },[])

    const [message, setMessage] = useState()
    function attemptSignIn(){
        setMessage()
        let emailInputValue = document.getElementById("emailInput")?.value
        let passInputValue = document.getElementById("passInput")?.value
        if(!emailInputValue || emailInputValue.length < 6)
            setMessage(`Please enter a valid username or email
(6 characters mininmum)`)
        else if(!passInputValue || passInputValue.length < 6)
            setMessage(`Please enter a valid password
(6 characters mininmum)`)
        else
            setMessage("User not found, please try again or reset password below.")
    }

    const [resettingPassword, setResettingPassword] = useState()
    function toggleResettingPassword(){
        setMessage()
        setResettingPassword(!resettingPassword)
    }
    function submittedUsernameForPassReset(){
        setMessage()
        let emailInputValue = document.getElementById("emailInput")?.value
        if(!emailInputValue || emailInputValue.length < 6)
            setMessage(`Please enter a valid username or email
(6 characters mininmum)`)
        else
            setMessage("If an account exists for "+emailInputValue+" an email with password reset instructions has been sent.")
    }

  return (
    <div className='pageHeight flexCenter'>

            <div className={'studentPortalInputFieldsBox '+(isMobile?" studentPortalInputFieldsBoxMobile":"")}>
                {!resettingPassword ? 
                <>
                    <div className='title title2'>
                        User Sign In
                    </div>
                    <div>
                        <input placeholder='Username or Email' id='emailInput' className='marginBottom10'></input>
                    </div>
                    <div>
                        <input placeholder='Password' type='password' id='passInput' className='marginBottom10'></input>
                    </div>
                    {message && <div className='centered smallText2'>
                        <>
                            {message}
                        </>
                    </div>}
                    <div style={{width: "calc(100% - 10px)"}}>
                        <button onClick={attemptSignIn} className='width100'>Sign In</button>
                    </div>
                    <div className='smallText2'>
                        Forgot password? <a onClick={toggleResettingPassword} className='inlineLink'>Reset it here.</a>
                    </div>
                </>
                :
                <>
                    <div className='title title2'>
                        Password Reset
                    </div>
                    <div>
                        <input placeholder='Username / Email' id='emailInput'></input>
                    </div>
                    <div className='centered smallText2'>
                        <>
                            {message}
                        </>
                    </div>
                    <div>
                        <button onClick={submittedUsernameForPassReset}>Reset Password</button>
                    </div>
                    <div className='smallText2'>
                        Remembered password? <a onClick={toggleResettingPassword} className='inlineLink'>Sign In Here.</a>
                    </div>
                </>    
                }
            </div>
    </div>
  )
}

export default AuthPage