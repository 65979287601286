import React from 'react'
import logo from "../../Images/staffFluxLogo2.png"
import { Link } from 'react-router-dom'
import { scrollTop } from '../../Global/Functions'
import "./BottomNav.css"

function BottomNav() {
  return (
    <div className='bottomNav'>
        <div className='bottomNavColumn bottomNavLogoColumn'>
            <Link to={"/"} title="Whalley University Home" onClick={scrollTop}>
                <img src={logo} className='logoImage'></img>
            </Link>
        </div>
        <div className='bottomNavColumn'>
            <div className='bottomNavColumnTitle'>Information</div>
            <div className='bottomNavColumnLinks'>
                <Link to={"/"}>Home</Link>
                <Link to={"/account"}>Account</Link>
                <Link to={"/all-positions"}>Open Positions</Link>
            </div>
        </div>
        <div className='bottomNavColumn'>
            <div className='bottomNavColumnTitle'>Access</div>
            <div className='bottomNavColumnLinks'>
                <Link to={"/account"} >For Employers</Link>
                <Link to={"/account"}>HR Administration</Link>
                <Link to={"/background-checks"} >Background Checks</Link>
                {/* <a href='emailto:info@Whalley.education?subject=Whalley Contact Link'>info@Whalley.education</a>
                <a href='tel:+1-978-346-0056'>+41 405 503 3048</a> */}
            </div>
        </div>
        
    </div>
  )
}

export default BottomNav