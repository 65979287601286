import React, { useEffect, useState } from 'react'
import Review from './Review'
import "./Reviews.css"
import StarRating from './StarRating/StarRating'
import { isMobile } from 'react-device-detect'

function Reviews({reviewsData}) {
    let exampleReviews= [
        {
          reviewer: "Test User",
          rating: 4,
          comment: "This is a test review"
        },
        {
          reviewer: "Test User 2",
          rating: 4.8,
          comment: "This is a test review"
        },
        {
          reviewer: "Test User 3",
          rating: 3.5,
        },
        {
          reviewer: "Test User",
          rating: 4,
          comment: "This is a test review"
        },
        {
          reviewer: "Test User 2",
          rating: 5,
          comment: "This is a test review"
        },
        {
          reviewer: "Test User 3",
          rating: 5,
        },
      ]

      const [reviewDataState, setReviewDataState] = useState([])
      useEffect(()=>{
        if(reviewsData && Array.isArray(reviewsData))
            setReviewDataState(reviewsData)
      },[reviewsData])

      function calculateOverall(){
        if(reviewDataState.length == 0)
            return "No reviews yet"

        let total = 0
        reviewDataState.forEach(reviewData => {
            total += reviewData.rating
        })
        return (Math.round((total / reviewDataState.length) * 10) /  10)
      }

  return (
    <div>
        <div>
            {"Overall Rating: "}
            <StarRating rating={calculateOverall()} showNumber></StarRating>
        </div>
        <div className={'reviewsColumn '+(isMobile ? " reviewsColumnMobile":"")}>
            {reviewDataState.map(reviewData => (
                <Review reviewData={reviewData}></Review>
            ))}
        </div>
    </div>
  )
}

export default Reviews