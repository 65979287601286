import { deleteObject, ref as storageRef} from "@firebase/storage"
import { push, refFromURL, set, ref } from "@firebase/database"
import { type } from "@testing-library/user-event/dist/type"
import { useDebugValue } from "react"
import { db, storage } from "../GlobalState/AppSlice"

/**
    Creates a duplicate of a JSON object without pointer references, only the values
*/
export function copyObject(object){
    if(typeof object !== "object") return object
    let tempObject = {}
    Object.entries(object).forEach(([entryKey, entryValue]) => {
        if(typeof entryValue === "object")
            tempObject[entryKey] = copyObject(entryValue)
        else
            tempObject[entryKey] = entryValue
    })
    return tempObject
}

// Brings the scroll position of the page back to the top
export function scrollTop(customElementSelector){
    // The query selector used to get the element of which the scroll will be reset
    let elementSelector = "html"
    if(customElementSelector && typeof customElementSelector === "string") elementSelector = customElementSelector
    
    // Get a refecence to the element to reset scroll position for
    let htmlElement = document.querySelector(elementSelector)
    if(!htmlElement) return

    // Set the scroll position
    htmlElement.scrollTo(0, 0)
    
}

export function deleteImage(deleteImageObject){

    if(deleteImageObject?.storageURL){
        const imageRef = storageRef(storage, deleteImageObject?.storageURL)
        deleteObject(imageRef).then(()=>{
            // console.log("Image deleted.")
        }).catch((e)=>{
            // console.log("error deleting image")
        })
    }
    if(deleteImageObject?.profileDataPath){
        set(ref(db, deleteImageObject?.profileDataPath), null)
    }
}

export function dateString(){
    const currentdate = new Date()

    return currentdate.getFullYear() + "-"
    + (currentdate.getMonth()+1).toString().padStart(2, '0') + "-"  
    + currentdate.getDate().toString().padStart(2, '0') + "T"
    + currentdate.getHours().toString().padStart(2, '0') + ":"  
    + currentdate.getMinutes().toString().padStart(2, '0') + ":" 
    + currentdate.getSeconds().toString().padStart(2, '0');
}
export function dateStringUTC(){
    const currentdate = new Date()

    return currentdate.getFullYear() + "-"
    // The month is an index 0 - 11
    + (currentdate.getUTCMonth()).toString().padStart(2, '0') + "-"  
    + currentdate.getUTCDate().toString().padStart(2, '0') + "T"
    + currentdate.getUTCHours().toString().padStart(2, '0') + ":"  
    + currentdate.getUTCMinutes().toString().padStart(2, '0') + ":" 
    + currentdate.getUTCSeconds().toString().padStart(2, '0');
}

/**
 * Takes in a datestring and flag variable, returns a formatted date string
 * @param {*} dateString A date string formatted as YYYY-MM-DD:hh:mm:ss
 * @param {*} showDate If this is set to true the funciton will always prepend a the date, else it will just show the time if its today and add the date when on another day
 */
export function readableDateString(dateString, showDate){
     if(!dateString) return

    // console.log("dateString")
    // console.log(dateString)

    let today = new Date()
    let date = new Date(dateString)
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset())

    let dateStringNew = date.getHours().toString()+":"+date.getMinutes().toString().padStart(2, '0')+":"+date.getSeconds().toString().padStart(2, '0')

    // console.log("dateStringNew")
    // console.log(dateStringNew)

    // console.log("today.getDate()")
    // console.log(today.getDate())
    // console.log("date.getDate()")
    // console.log(date.getDate())

    if(today.getDate() > date.getDate())
        dateStringNew = date.toLocaleString("default", {weekday: 'long'}) + " " + date.toLocaleString("default", {month: 'long'}) +" "+ (date.getDate()) + " "+ dateStringNew

    return dateStringNew

}
/**
 * Looks for an input field with ID: inputID, gets that value, adds it the the given object, and returns that object
 * @param {*} object = the object to add the attribute to
 * @param {*} attribute = the key to add the new value to
 * @param {*} inputID = the ID of the input field
 * @returns 
 */
export function addAttributeFromInput(object, attribute, inputID){
    // console.log("addAttributeFromInput. attribute: "+attribute+" inputID: "+inputID+" starting object: ")
    // console.log(object)

    // console.log("adding "+attribute+" from "+inputID)

    if(!object || !attribute || !inputID) return object
    let value = getByID(inputID)
    // console.log("::got value: "+value)

    if(!value){
        console.log("did not find value for input with ID: "+inputID)
        return object
    } 

    let tempObject = {...object}
    tempObject[attribute] = value

    // console.log("addAttributeFromInput updated object: ")
    // console.log(tempObject)
    return tempObject
} 
/**
 * Get the value of an input by the input element id
 */
export function getByID(inputID){
    let element = document.getElementById(inputID)
    // console.log("element")
    // console.log(element)
    // console.log(element.value)
    // console.log('document.querySelectorAll("#inputID")')
    // console.log(document.querySelectorAll("#"+inputID))
    if(element)
        return element?.value
    else
        return null
}
export function ellipsisString(string, maxLength){
    if(typeof string !== "string") return ""
    if(string.length < maxLength)
        return string
    return string.substring(0, maxLength - 3)+" ..."
}
export function objectLength(object){
    if(!object || typeof object !== "object") return 0
    return Object.entries(object).length
}

/** 
 *  Inputs: (userID, chatID, allUsers, otherUserID ) 
 *  Outpus: { otherUserProfileData, chatPreviewData, otherUserID, chatID}
*/
export function getInfoForChat(userID, chatID, allUsers, otherUserID, chats, chatData){

    if(!allUsers || typeof allUsers !== "object")
        return

    let logLevel = 0

    if(logLevel > 2){
        console.log("==================== Start getInfoForChat ")
        console.log("userID: "+userID)
        console.log("chatID: "+chatID)
        console.log("otherUserID: "+otherUserID) // (TODO could also access this through the most recent message of the chat Data)
        console.log("allUsers")
        console.log(allUsers)
        console.log("chats")
        console.log(chats)
    }

    // Ensure valid inputs. Need a userID and allUsers object, need either chatID or otherUserID
    if( !userID || typeof allUsers !== "object" || (!chatID && !otherUserID)){
        if(logLevel > 0){
            console.log("( !userID || typeof allUsers !== 'object' || (!chatID && !otherUserID))")
        }
         return {}
    } 
    
    // Get the user's data from all users and the userID
    let userData = allUsers[userID]
    if(logLevel > 2){
        console.log("1) found user data")
        console.log(userData)
    }

    // Get the users's chats from their userData (TODO could also just access thair chats directly)
    let userChats = userData?.chats || chats
    if(logLevel > 2){
        console.log("2) found user chats")
        console.log(userChats)
    }

    // If there is no chatID find it based on the chats preview data andOtherUserID
    let localChatID = chatID
    if(userChats && (typeof userChats === "object") && !localChatID){
        Object.entries(userChats).forEach(([chatID, chatData]) => {
            if(logLevel > 2){
                console.log("2.5) looking for "+otherUserID+"in chat data?.userIDs:")
                console.log(chatData?.userIDs)
            }
            chatData?.userIDs?.forEach(chatUserID => {
                if(chatUserID === otherUserID)
                    localChatID = chatID
            })
        })
    }
    if(logLevel > 2){
        console.log("3) found local chat ID")
        console.log(localChatID)
    }


    // Get the chat preview data based on the user's chats (if there are user chats) and the found chatID
    let chatPreviewData
    if(localChatID && userChats && typeof userChats === "object")
        chatPreviewData = userChats[localChatID]
    if(logLevel > 2){
        console.log("4) found chatPreviewData")
        console.log(chatPreviewData)
    }


    // If there is no other user ID find it based on the found chatPreviewData and found chat ID
    let localOtherUserID = otherUserID
    if((typeof chatPreviewData === "object") && !localOtherUserID){
        chatPreviewData?.userIDs?.forEach(userInChatID => {
            // If the user ID its looking at is not the users ID it must be another user's ID
            if(userInChatID !== userID)
                localOtherUserID = userInChatID
        });
    }
    if(logLevel > 2){
        console.log("5) found localOtherUserID")
        console.log(localOtherUserID)
    }

    
    // Get the other users profile data based on the found other user ID (if there is one)
    let otherUserProfileData 
    if(localOtherUserID)
        otherUserProfileData= allUsers[localOtherUserID]?.profileData
    if(logLevel > 2){
        console.log("6) found otherUserProfileData")
        console.log(otherUserProfileData)
    }

    let returnObject = {
        // Data objects that were found
        otherUserProfileData: otherUserProfileData,
        chatPreviewData: chatPreviewData,
        // The values that may have been found or may have been profided
        otherUserID: localOtherUserID,
        chatID: localChatID,
    }

    if(logLevel > 1){
        console.log("7) got info for chat found data: ")
        console.log(returnObject)
    }
    if(logLevel > 2){
        console.log("==================== End getInfoForChat ")
    }

    return returnObject
}

  /**
   * Takes in an object of objects and only adds object wity matching type attribute. Returns an array
   */
  export function filterByType(objetOfObjects, type){

  }
  /**
    Creates an array from the object. Puts the key for each sub-object in the id attribute of the objects in the array. 
  */
    export function objectToArray(object){
        if(typeof object !== "object") return []
        let tempArray = []
        Object.entries(object).forEach(([objectID, objectData]) => {
            tempArray.push({id: objectID, ...objectData})
        })
        return tempArray
    }

    export function companyDataToPositionsArray(companiesData, companyID){

        if(!companyID || !companiesData || typeof companiesData !== "object") return []
        let thisCompanyData = companiesData[companyID]
        if(!thisCompanyData?.openPositions || typeof thisCompanyData.openPositions !== "object") return

        let tempCompanyPositions = []
        Object.entries(thisCompanyData.openPositions).forEach(([positionID, positionData]) => {
            tempCompanyPositions.push({...positionData, company: thisCompanyData.name, companyID: companyID, positionID: positionID})
        })

        return tempCompanyPositions
    }

    export function getPositionData(companiesData, companyID, positionID){
        if(!companyID || ! positionID|| !companiesData || typeof companiesData !== "object") return {}
         
        let companyData = companiesData[companyID]
        if(!companyData) return {}
        
        let positionData = companyData?.openPositions[positionID]
        if(!positionData) return {}

        let dataToReturn = {...positionData, company: companyData.name, companyID: companyID, positionID: positionID} 
        return dataToReturn
    }

  /**
   * Get the key from each object and create an array from the values
   */
  export function objectKeyToArray(object, key){
    if(typeof object !== "object") return []
    let tempArray = []
    Object.entries(object).forEach(([objectID, objectData]) => {
        tempArray.push(objectData[key])
    })
    return tempArray
  }
  /**
   * Takes in an object and sorts by dateTime attribute of objects. Returns sorted array
   */
  export function sortChatsByDate(objetOfObjects){
    // Ensure a valide input
    if(!objetOfObjects || typeof objetOfObjects !== "object") return []
    // Where the sorted objects will be stored
    let tempSortedObjects = []
    // Look through each object in the provided object and place it
    Object.entries(objetOfObjects).forEach(([objectKey, objectData]) => {
        // Index at which the new object will be added to the sorted array
        let addIndex = 0
        // Look through each sorted object and find the place where the new object should be put (save in addIndex)
        tempSortedObjects.forEach((sortedObject, index) => {
            if(sortedObject?.mostRecentMessage?.dateTime > objectData?.mostRecentMessage?.dateTime){
                // Save the index so the object can be added there
                addIndex = index +1

            }
        })

        // Put the id of the object in the data object so it can be accessed from the object in the array
        let tempObjectData = {...objectData}
        tempObjectData.id = objectKey
        // tempSortedObjects.push(tempObjectData)
        tempSortedObjects.splice(addIndex, 0, tempObjectData)

    })
    
    // Log to check accuracy
    // console.log("sorted object of objects: ")
    // console.log(objetOfObjects)
    // console.log("into sorted array")
    // console.log(tempSortedObjects)
    
    return tempSortedObjects
  }
  export function sortByDate(objetOfObjects){
    // Ensure a valide input
    if(typeof objetOfObjects !== "object") return []
    // Where the sorted objects will be stored
    let tempSortedObjects = []
    // Look through each object in the provided object and place it
    Object.entries(objetOfObjects).forEach(([objectKey, objectData]) => {
        // Index at which the new object will be added to the sorted array
        let addIndex = 0
        // Look through each sorted object and find the place where the new object should be put (save in addIndex)
        tempSortedObjects.forEach((sortedObject, index) => {
            if(sortedObject?.dateTime < objectData?.dateTime){
                // Save the index so the object can be added there
                addIndex = index +1

            }
        })

        // Put the id of the object in the data object so it can be accessed from the object in the array
        let tempObjectData = {...objectData}
        tempObjectData.id = objectKey
        // tempSortedObjects.push(tempObjectData)
        tempSortedObjects.splice(addIndex, 0, tempObjectData)

    })
    
    // Log to check accuracy
    // console.log("sorted object of objects: ")
    // console.log(objetOfObjects)
    // console.log("into sorted array")
    // console.log(tempSortedObjects)
    
    return tempSortedObjects
  }
  export function randomBannerImage(){
    let imageUrlArray = [
        // Blue Circles
        // "https://static.vecteezy.com/system/resources/thumbnails/001/234/358/small/modern-blue-halftone-banner-background.jpg",
        // Sun Grass Field Trees
        // "https://static.vecteezy.com/system/resources/thumbnails/012/865/505/small/idyllic-mountain-panoramic-landscape-fresh-green-meadows-and-blooming-wildflowers-sun-ray-beautiful-nature-countryside-view-rural-sunny-outdoor-natural-bright-banner-nature-spring-summer-panorama-photo.jpg",
        // Ferns Globe
        "https://www.rex3.com/hs-fs/hubfs/Rex_2022/Images/GreenPrinting-Rex3/green-printing-banner.jpg?width=1588&height=549&name=green-printing-banner.jpg",
        // Autumn
        // "https://st.depositphotos.com/6787284/60645/i/450/depositphotos_606452878-stock-photo-autumn-scene-fall-red-yellow.jpg",
        // Mountains Sunset
        // "https://media.istockphoto.com/id/155288168/photo/max-patch-sunset.jpg?s=612x612&w=0&k=20&c=kS0FyNaQguD3PhpYnvfl6SbYtJw0CiItKDxx5lDxGU4=",
        // Forest Sun
        "https://as1.ftcdn.net/v2/jpg/03/14/26/92/1000_F_314269220_SKNZlUMDRvv9J4qdTywZyNxGRujFVlQn.jpg",
        // Pine Trees Fog
        // "https://www.chelseagreen.com/wp-content/uploads/forest_bannersnack.jpg",
        // Flatiron Mountains
        "https://images.discerningassets.com/image/upload/c_fit,h_1500,w_1500/c_fit,fl_relative,h_1.0,l_ammaidtawnihnrcdopqc,o_40,w_1.0/v1482616140/colorado-photos_DSC4181_mjfmow.jpg",
        // Flatiron Mountains 2
        // "https://media.istockphoto.com/id/149958305/photo/flatiron-morning-light-boulder-colorado.jpg?s=612x612&w=0&k=20&c=oBeBUI4lWEx15VzQriQ3SC8CcIxCAvysF0pWhAY-T8k=",
        // Mountains
        "https://images.unsplash.com/photo-1604223190433-2a5cc5044fda?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE0fHx8ZW58MHx8fHx8&w=1000&q=80",
        
    ]
    return imageUrlArray[Math.floor(Math.random() * imageUrlArray.length)]
  }
  export function inchesToFeet(inches){
    let inchesInt = 0
    if(typeof inches === "string")
        inchesInt = Number.parseInt(inches)

    let feetValue = Math.floor(inchesInt / 12)
    let remainingInches = inchesInt % 12
    if(remainingInches == 0)
        return feetValue + "'"
    else
        return feetValue + "' " + remainingInches + '"'

  }
  export function filterUsersToDonors(allUsersObject){
    // console.log("filterUsersToDonors")
    // console.log(allUsersObject)
    if(!allUsersObject || typeof allUsersObject !== "object")
        return {}
    let tempReturnObject = {}
    Object.entries(allUsersObject).forEach(([userID, userData]) => {
        if(userData?.profileData?.userType === "donor"){
            tempReturnObject[userID] = userData
        }
    })
    
    // console.log("tempReturnObject")
    // console.log(tempReturnObject)

    return tempReturnObject
  }
  export function readableJson(json){
    if(!json || typeof json !== "object") return ""
    let jsonString = JSON.stringify(json)
    jsonString = jsonString.replaceAll(",",",\n  ")
    jsonString = jsonString.replaceAll("{","{\n  ")
    jsonString = jsonString.replaceAll("}",",\n}")
    return jsonString
  }
  export function userIDToName(allUsers, userID){
    return allUsers?.[userID]?.profileData?.name

  }
  /**
   * addProfileDataToUserData(profileData, userData)
   * @param {*} profileData 
   * @param {*} userData 
   * @returns 
   */
  export function addProfileDataToUserData(profileData, userData){
    let tempUserData = {...userData}
    if(!tempUserData.profileData)
        tempUserData.profileData = {}

    Object.entries(profileData).forEach((([key, value]) => {
        tempUserData.profileData[key] = value
    }))

    return tempUserData

  }

    /**
     * Push a notification to the admin section of the database
    */
    export function pushNotification(notificationData){  
        // console.log("pushNotification notificationData")
        // console.log(notificationData)
        let newNotificationDBRef = push(ref(db, "freeFer/admin/notifications"))
        set(newNotificationDBRef, {...notificationData, dateTime: dateString()})
    }
    export function deleteNotification(notificationID){
        set(ref(db, "freeFer/admin/notifications/"+notificationID), null)
    }
    export function deleteAllNotificaions(){
        set(ref(db, "freeFer/admin/notifications"), null)
    }
    export function markNotificationAsRead(notificationID){
        set(ref(db, "freeFer/admin/notifications/"+notificationID+"/read"), true)
    }
    export function reverseArray(array){
        if(!array || !Array.isArray(array)) return []
        
        let tempArray = []
        array.forEach(item => {
            tempArray = [item, ...tempArray]
        })
        return tempArray
    }
    export function isValidObject(object){
        if(!object || typeof object !== "object") return false
        return true
    }
    export function sortUsers(usersObject){
        /*
            sort by: 
                
                index if there is one
                else:
                    active status
                    last active date

            look at each user, compare their index to the index of this user
            arr.splice(index, 0, item)

            seperaty by userType
            donors position set by index
            other users set by active now
            within active now sort by last active

        */
        if(!usersObject || typeof usersObject !== "object") return []
        
        // Convert the object into an array
        let usersArray = []
        Object.entries(usersObject).forEach(([userID, userData]) => {
            let tempUserData = {...userData, id: userID}
            usersArray.push(tempUserData) 
        })

        // console.log("====================")
        // console.log("usersArray: ")
        // console.log(usersArray)
        // console.log("beginning user sort")

        // Sorting by index:

        // Where the sorted users will be stored
        let tempSortedUsers = []
        // Look at each user in the array to place them
        usersArray.forEach((userToPlace, index) => {

            // console.log("adding user "+userIndex(userToPlace))

            // Flag to make sure the user is added at least and only once
            let addedUser = false
            // Used for the while loop because the tempSortedUsers.forEach will change dynamically
            let sortedArrayLength = tempSortedUsers.length
            // Index counter
            let i = 0
            // Look at each user already in the sorted array
            while(i < sortedArrayLength){
                // If the index of the user being placed is lower than the index of the user in this index of the ordered array
                if(!addedUser && userIndex(userToPlace) < userIndex(tempSortedUsers[i])){
                    // console.log("added "+userIndex(userToPlace))
                    // Place the user to place at this index
                    tempSortedUsers.splice(i, 0, userToPlace)
                    // Set this flag so the user is only added once
                    addedUser = true
                }
                i++
            }
            // If the user was not added in a position add them at the end
            if(!addedUser){
                // console.log("pushed "+userIndex(userToPlace))
                tempSortedUsers.push(userToPlace)
            }

        })

        // Sort by user type
        let tempDUsers = []
        let tempUUsers = []
        tempSortedUsers.forEach(user => {
            if(user?.profileData?.userType === "donor")
                tempDUsers.push(user)
            else
                tempUUsers.push(user)
        })

        // Sort the u users by last active
        // userData.lastActive
        let tempSortedUUsers = []
        // Go through all the uUsers in the array
        tempUUsers.forEach((uUser, index) => {
            // If the user has no last active data just push them to the array without any loop
            if(!uUser.lastActive){
                // console.log("pushed user to end "+uUser.lastActive)
                tempSortedUUsers.push(uUser)
            }
            else{
                // Get the length so we know how many to go through (cant use forEach because it will update dynamically and re-add users)
                let tempSortedUUsersLength = tempSortedUUsers.length
                let i = 0
                // Flag to make sure the users is added at least and onlyonce
                let addedUser = false
                while (i < tempSortedUUsersLength){
                    // If the sorted user is not active put the user at the beginning
                    if(!tempSortedUUsers[i]?.lastActive){
                        // console.log("put user at index "+i+" becasue sorted user has no last active")
                        tempSortedUUsers.splice(i, 0, uUser)
                        addedUser = true
                        // Skip the rest of the loop to save time
                        i = tempSortedUUsers.length
                    }
                    // Check to see if the user was active earlier than this sorted user 
                    else if(!addedUser && (uUser.lastActive > tempSortedUUsers[i]?.lastActive)){
                        // console.log("added at index"+i+" uUser.lastActive: "+uUser.lastActive+"  tempSortedUUsers[i]?.lastActive: "+ tempSortedUUsers[i]?.lastActive)
                        // If they were add them there
                        tempSortedUUsers.splice(i, 0, uUser)
                        addedUser = true
                        // Skip the rest of the loop to save time
                        i = tempSortedUUsers.length
                    }
                    i++
                } 
                if(!addedUser){
                    // console.log("pushed user to end")
                    // console.log(uUser)
                    tempSortedUUsers.push(uUser)
                }
            }
            // console.log("tempSortedUUsers placed "+index)
            // console.log(tempSortedUUsers)
        })

        // let returnArray = [...tempSortedUUsers]
        let returnArray = [...tempSortedUUsers, ...tempDUsers]
        return returnArray
    }
    function userIndex(userData){
        return Number.parseInt(userData?.profileData?.displayIndex) || 100000
    }
    // ============================================================
    // #region
    // #endregion