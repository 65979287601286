import React from 'react'
import "./Home.css"
import Banner from './Components/Banner'
import ImageTextSection from './Components/ImageTextSection'
import FourTiles from '../Tile/FourTiles'
import BackgroundBox from './Components/BackgroundBox'
import CircleButton from './Components/CircleButton'
import CirclesSection from './Components/CirclesSection'
import fingerPrintCheck from "../../Images/finger print check.png"
import searchPerson from "../../Images/search person.png"
import staffing from "../../Images/staffing.jpg"
import workforceAnalytics from "../../Images/workforceAnalytics.png"
import waterPlantGuy from "../../Images/waterPlantGuy.jpg"
import graduateIcon from "../../Images/graduate.jpg"
import { isMobile } from 'react-device-detect'


function Home({key}) {


    let sectionsData = [
      {
        "title": "Pre-Employment Background Check Solutions",
        "description": "Our Pre-Employment Background Check Solutions offer a comprehensive approach to screening potential employees, ensuring you hire individuals of integrity and reliability. We conduct in-depth checks into criminal records, sex offenses, financial sanctions, and professional conduct. This thorough process helps protect your organization from potential risks associated with hiring.\n\nUtilizing our extensive National Criminal File, we provide you with detailed and up-to-date information. This database is consistently updated, adding 6000 to 8000 new records daily and covering all 50 states, Washington D.C., and Guam. It includes sex offender registry records, where available, giving you a comprehensive view of an applicant's background.",
        imageUrl: fingerPrintCheck,
      },
      {
        "title": "Customized Staffing Solutions",
        "description": "Our Customized Staffing Solutions are specifically designed to meet the unique needs of your business. Whether you're seeking temporary, temp-to-hire, or direct hire employees, we provide a broad network of qualified candidates across various industries. This service is tailored to ensure that you find the perfect match for your company's specific requirements.\n\nOur expert team collaborates closely with you to understand your staffing needs. We take into account your business culture, the specific skills required for the role, and the dynamics of your existing team to ensure a seamless integration of new staff. Our goal is to provide candidates who not only meet the job requirements but also contribute positively to your company's environment.",
        imageUrl: staffing,
      },
      {
        "title": "Executive Search and Headhunting Services",
        "description": "Our Executive Search and Headhunting Services are dedicated to finding and attracting top-tier professionals for senior and executive-level positions. We understand that the success of your organization hinges on the quality of its leadership, and our goal is to connect you with individuals who can drive your business forward. Our approach combines industry insights, extensive networks, and a comprehensive vetting process.\n\nWe employ a targeted search strategy to identify potential candidates who not only possess the necessary skills and experience but also align with your company's culture and values. Our extensive network includes industry leaders and hidden talents, ensuring a wide pool of high-caliber professionals. We then engage in a thorough vetting process, assessing each candidate's qualifications, leadership abilities, and potential for long-term success within your organization.",
        imageUrl: searchPerson,
      },
      {
        "title": "Workforce Analytics and Reporting",
        "description": "Our Workforce Analytics and Reporting services equip you with the tools to effectively analyze and manage your staffing and HR metrics. These solutions provide deep insights into workforce trends, helping you make informed decisions to optimize your HR strategies. By leveraging our analytics, you can gain a clearer understanding of your workforce dynamics and how they impact your business.\n\nOur analytics tools are designed to be user-friendly and highly informative, providing data on various aspects of workforce management. This includes analysis of staffing levels, turnover rates, employee engagement, and productivity metrics. These insights are crucial for identifying areas of improvement, ensuring compliance, and planning future workforce needs.",
        imageUrl: workforceAnalytics,
      },
      {
        "title": "Youth and Graduate Employment Programs",
        "description": "Our Youth and Graduate Employment Programs are specifically designed to support the next generation of professionals. We focus on providing opportunities that bridge the gap between academic learning and the professional world. Our programs offer internships, entry-level job placements, and career guidance, aimed at nurturing young talent and facilitating their successful transition into the workforce.\n\nThe internships and entry-level positions we provide are in diverse fields, offering real-world experience and the opportunity to develop essential professional skills. We collaborate with a variety of organizations to ensure a broad range of opportunities. Additionally, our career guidance services help young professionals in making informed career choices, resume building, and interview preparation, equipping them with the tools for career success.",
        imageUrl: graduateIcon,
      },
      {
        "title": "Industry-Specific Staffing Solutions",
        "description": "Our Industry-Specific Staffing Solutions cater to the unique staffing requirements of various sectors, including technology, healthcare, finance, and manufacturing. We understand that each industry has its own set of challenges and skill requirements. Our specialized approach ensures that you get the right talent equipped with industry-specific knowledge and experience.\n\nWe have developed strong expertise in these sectors, allowing us to source candidates who are not just qualified, but also a perfect fit for your industry's specific demands. Our rigorous screening process evaluates candidates for their technical abilities, industry experience, and cultural fit, ensuring that they can seamlessly integrate into your team and contribute effectively from day one.",
        imageUrl: waterPlantGuy,
      },
    ]
  
    return (
      <>

            <Banner
                title={"All in one staffing solutions"}
                description={"From posting positions, to job searches, to running verifications, and onboarding, we have you covered every step of the way."}
                buttonText={"Post A Position"}
                buttonLink={"/account"}
                buttonText2={"View Positions"}
                buttonLink2={"/all-positions"}
              ></Banner>

            <div className='pageInnerWidth'>
              {sectionsData.map((data, index) => (
                <ImageTextSection 
                  imageRight={index%2 == 0}
                  title={data?.title}
                  description={data?.description}
                  imageUrl={data?.imageUrl}
                ></ImageTextSection>
              ))}

              {/* <FourTiles></FourTiles> */}



            </div>
            <div className='pageSection'>

              <CirclesSection></CirclesSection>

              <BackgroundBox></BackgroundBox>
            
            </div>

      </>
  )
}

export default Home