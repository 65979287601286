import React, { useEffect, useState } from 'react'
import stars from "./Images/starsTransparent.png"
import "./StarRating.css"

function StarRating({rating, showNumber}) {
    const [ratingState, setRatingState] = useState(4.5)
    useEffect(()=>{
        if(rating != undefined)
            setRatingState(rating)
    },[rating])

  return (
    <div className='starRatingRow'>
            {rating === "No reviews yet" ?
                <div>
                    No Reviews Yet
                </div>
                :
                <>
                    <div className='starRatingBox'>
                        <img src={stars} className='imageOverlay'></img>
                        {/* <div className='starRatingBackground'></div> */}
                        <div className='starRatingBackground' style={{width: ((100 * (ratingState / 5)))+"%"}}></div>
                    </div>
                    <div className='starRatingText'>
                        {showNumber && " ("+ratingState +" / 5) "}
                    </div>
                </>
            }
    </div>

  )
}

export default StarRating