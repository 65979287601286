import React from 'react'
import ApplyPage2 from './ApplyPage2'
import { useParams } from 'react-router-dom'

/*

    What information is needed here?
    SN, taxID, ID img
    need to app to see

*/
function BackgroundCheckApply() {

    const { applicantName } = useParams()
    let applicantFirstName
    let applicantLastName
    if(applicantName){
        let nameParts = applicantName.split("-")
        if(Array.isArray(nameParts) & nameParts.length > 0)
            applicantFirstName = nameParts[0]
        if(Array.isArray(nameParts) & nameParts.length > 1)
            applicantLastName = nameParts[1]
    }

    const applicationData = {
        title: "Background Check",

    }

    const backgroundCheckInputStaps = [
        {
            title: "Basic Info",
            // bottomText: "*!* You will need to remember your pin to resume your application later, without it you may have to start over. Consider writing it down. *!*",
            bottomText: "Next you will input some more details and complete the application.",
            inputFields: [
                {
                    key: "firstName",
                    label: "Legal First Name",
                    type: "text",
                    // defaultValue: applicantFirstName, // This won't save into the db unless all of the inputs are saved when one changes, currently just the one changing saves
                },
                {
                    key: "middleName",
                    label: "Legal Middle Name",
                    type: "text",
                    // defaultValue: applicantLastName, // This won't save into the db unless all of the inputs are saved when one changes, currently just the one changing saves
                },
                {
                    key: "lastName",
                    label: "Legal Last Name",
                    type: "text",
                },
                {
                    key: "age",
                    label: "Age",
                    type: "text",
                    fullWidth: false,
                },
                {
                    key: "gender",
                    label: "Gender",
                    type: "select",
                    fullWidth: false,
                    options: [
                        "",
                        "Male",
                        "Female",
                        "Other",
                        "Prefer Not To Say",
                    ]
                },
                {
                    key: "mailingAddress",
                    label: "Mailing Address",
                    type: "text",
                    fullWidth: true,
                },
                // May already have these
                {
                    key: "phone",
                    label: "Phone Number",
                    type: "text",
                },
                {
                    key: "email",
                    label: "Email Address",
                    type: "text",
                },
            ]
        },
        {
            title: "Detailed Info",
            bottomText: "Next: You can review your inputs before submission.",
            inputFields: [
                {
                    key: "uln",
                    label: "Unique Learner Number (ULN)",
                    type: "text",
                },
                {
                    key: "idFile",
                    label: "Upload a picture of drivers licence or government issued photo ID.",
                    type: "file",
                    fullWidth: true,
                },
                {
                    key: "idFileBack",
                    label: "Upload a picture of the back side of your ID.",
                    type: "file",
                    fullWidth: true,
                },
            ]
        },
     
    ]


  return (
    <div className='pageHeight flexCenter'>
        <div className='width80'>
            <ApplyPage2
                applicationData={applicationData}
                stepsDataParam={backgroundCheckInputStaps}
                applicationNameParam={"integrity background check"+(applicantName ? (" "+applicantName) : "")}
            ></ApplyPage2>
        </div>
    </div>
  )
}

export default BackgroundCheckApply