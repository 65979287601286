import React, { useState } from 'react'
import logo from "../../Images/staffFluxLogo2.png"
import menuIcon from "../../Images/hamburger-menu-icon-w.png"
import { Link } from 'react-router-dom'
// import { scrollTop } from '../Global/Functions'
import "./TopNav.css"
import { scrollTop } from '../../Global/Functions'

function TopNav() {

  const [menuOpen, setMenuOpen] = useState()

  function closeMenu(){
    setMenuOpen(false)
  }

  return (
    <div className='topNav'>
        <Link to={"/"} title="Whalley University Home" onClick={scrollTop}>
            <div className='logoImage'>
              <img src={logo}></img>
          </div>
        </Link>
        <div className='topNavButtons'>
          <Link to={"/"} onClick={closeMenu}>Home</Link>
          <Link to={"/all-positions"} onClick={closeMenu}>Open Positions</Link>
          <Link to={"/background-checks"} onClick={closeMenu}>Background Checks</Link>
          <Link to={"/account"} onClick={closeMenu}>Account</Link>
        </div>
        <div className='topNavButtonsMenu' onClick={()=>setMenuOpen(!menuOpen)}>
          <img src={menuIcon} title="Menu"></img>
        </div>
        {/* 4 is the number of buttons in the menu, could put this in a json with the link and text then user the lenth of that to calc the height */}
        <div className={'topNavButtonsMenuButtons '+(menuOpen ? " topNavButtonsMenuButtonsOpen":"")} style={{height: (menuOpen? ((4 * 61) + 20)+"px":"0px")}}>
          <Link to={"/"} onClick={closeMenu}>Home</Link>
          <Link to={"/all-positions"} onClick={closeMenu}>Open Positions</Link>
          <Link to={"/background-checks"} onClick={closeMenu}>Background Checks</Link>
          <Link to={"/account"} onClick={closeMenu}>Account</Link>
        </div>
    </div>
  )
}

export default TopNav