import React from 'react'
import { Link } from 'react-router-dom'
import staffingBanner from "../../../Images/staffingBanner2Overlayed2Flipped.jpg"
import { isMobile } from 'react-device-detect'

function Banner({title, subtitle, description, imageUrl, buttonText, buttonLink, buttonText2, buttonLink2}) {

    return (
    <div className='banner'>
        {/* <img src={"../../"+programBannerURL()} className='programBannerImg'></img> */}
        <img src={imageUrl} className={'bannerImg ' +(isMobile ? " bannerImgMobile":"")}></img>
        <div className={'bannerTextSection'+(isMobile ? " bannerTextSectionMobile":"")}>
            {subtitle && <div className='programBannerSubtitle'>{subtitle}</div>}
            <div className={'bannerTitle ' +(isMobile ? " bannerTitleMobile":"")}>
                {title}
            </div>
            <div className='bannerDescription'>
                {description}
            </div>
            {buttonText && 
                <Link to={buttonLink}>
                    <button className=''>{buttonText}</button>
                </Link>
            }
            {buttonText2 && 
                <Link to={buttonLink2}>
                    <button className=''>{buttonText2}</button>
                </Link>
            }
        </div>
    </div>
  )
}

Banner.defaultProps = {
    title: "Banner Title",
    description: "Banner description.",
    imageUrl: staffingBanner,
}

export default Banner

