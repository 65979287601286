import React from 'react'
import placeholderImage from "../../../Images/bannerImageBeforeVideo.jpg"
import { isMobile } from 'react-device-detect'

function ImageTextSection({imageUrl, title, text, imageRight, description}) {
  return (
    <div className='width100 textAlignLeft pageSection'>
        {/* {!imageRight && 
            <div className='inlineBlock  mobile100'>
                <img src={imgUrl} className='roundedBox'></img>
            </div>
        } */}
        <div className={"inlineBlock " + (isMobile ? " centered":(imageRight ? " floatRight":""))}>
        {/* {"imageRight: "+imageRight} */}
            <img src={imageUrl} className='roundedBox'></img>
        </div>
        <div className='inlineBlock widthMinus340 textSection mobile100'>
            <div className='title'>{title}</div>
            <div className='description'>
                <pre>
                    {description}
                </pre>
            </div>
        </div>
        {/* {imageRight && 
            <div className='inlineBlock  mobile100'>
                <img src={imgUrl} className='roundedBox'></img>
            </div>
        } */}
    </div>
  )
}

ImageTextSection.defaultProps = {
    imgUrl: placeholderImage,
    title: "Pre-Employment Background Check Solutions",
    description: "section text",
}

export default ImageTextSection