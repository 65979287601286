import { Link } from 'react-router-dom'
import React from 'react'
import placeholderImgUrl from "../../Images/bannerImageBeforeVideo.jpg"
import "./Tile.css"

function Tile({imageUrl, title, title2, description, link, customClass}) {

  return (
    <div className={'tile '+(customClass ? customClass : "")}>
        <div className='tileImage'>
            <img src={imageUrl}></img>
            {title && <div className='tileTitle'>{title}</div>}
        </div>
        {title2 && <div className='tileTitle2'>{title}</div>}
        {description && <div className='tileDescription'>
          <pre>
            {description}
          </pre>
        </div>}
        {link &&
            <Link to={link}>
                <button className='readMoreButton'>Read More</button>
            </Link>
        }
    </div>
  )
}

Tile.defaultProps = {
  imageUrl: placeholderImgUrl,
  title: "Title",
  // description: "This is a tile.",
  
}

export default Tile