
import React, { useEffect, useState } from 'react'
import "./CompanyOverview.css"
import { useParams } from 'react-router-dom'
import { companyData } from '../../Data/Data'
import PositionTile from '../Positions/PositionTile'
import { companyDataToPositionsArray, objectToArray } from '../../Global/Functions'
import StarRating from '../Reviews/StarRating/StarRating'
import Reviews from '../Reviews/Reviews'
import CompanyPositions from '../Positions/CompanyPositions'
import { isMobile } from 'react-device-detect'

function CompanyOverview() {
  
    const {companyID} = useParams()
  
    const [selectedTab, setSelectedTab] = useState("Overview")
    const tabOptions = ["Overview", "Positions", "Salaries", "Reviews"]

    const [companyDataState, setCompanyDataState] = useState()
    useEffect(()=>{
        let tempCompanyData = companyData[companyID]
        console.log("tempCompanyData: ")
        console.log(tempCompanyData)
        if(tempCompanyData)
            setCompanyDataState(tempCompanyData)
        else
            setCompanyDataState({name: "Company Not Found"})
    },[])

    function sectionDisplay(){
        if(selectedTab === "Positions")
            return (
                <div>
                    {companyDataToPositionsArray(companyData, companyID).map(positionData => (
                        <PositionTile positionData={positionData}></PositionTile>
                    ))}
                </div>    
            )
        if(selectedTab === "Overview")
            return (
                <div>
                    <div style={{width: (isMobile?"90%":"70%")}} className='inlineBlock pad20Vertical'>
                        <pre>
                            {companyDataState?.description}
                        </pre>
                    </div>
                    <div>
                        <hr className='inlineBlock '></hr>
                        <div className='title'>Open Positions</div>
                        {companyDataToPositionsArray(companyData, companyID).map(positionData => (
                            <PositionTile positionData={positionData}></PositionTile>
                            ))}
                    </div> 
                    <div>
                        <hr></hr>
                        <div className='title'>Company Reviews</div>
                        <Reviews reviewsData={companyDataState?.reviews}></Reviews>
                    </div>      
                </div>    
            )
        if(selectedTab === "Salaries")
            return (
                <div>
                    No Salary Data Yet.
                </div>    
            )
        if(selectedTab === "Reviews"){
            // Calculate the overall rating
            
            // Display it along with the number of reviews and the review comments
            return (
                <div>
                    <Reviews reviewsData={companyDataState?.reviews}></Reviews>
                </div>    
            )
        }
    }

    return (
    <div className='pageHeight'>
        <div className='title companyOverviewTitle'>
            {companyDataState?.name}
        </div>
        <div className='flexCenter'>
            <div className='flexRow copmanyOverviewButtons'>
                {tabOptions.map(optionName => (
                    <div className={'sectionButton '+(selectedTab === optionName ? " sectionButtonSelected":"")} onClick={()=>setSelectedTab(optionName)}>{optionName}</div>
                ))}
            </div>
        </div>
        <div className='width100 center'>
            <div className='companyOverviewSectionDisplay'>
                {sectionDisplay()}
            </div>
        </div>
    </div>
  )
}

export default CompanyOverview