import React from 'react'
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';

function PositionTile({positionData, positionClickFunction}) {

    // Styling form another job postins site
    // Display all of the data and the company that posted it (if in the data)
    // When the button is clicked it opens a url that displays that position's data with an apply box

  return (
    <div className='positionTile borderBox ' onClick={()=>positionClickFunction(positionData)}>
        <div>
          {isMobile ? 
            <div className='positionTileTitle hoverUnderline inlineBlock'>{positionData?.title}</div>          
            :  
            <Link to={"/"+positionData?.companyID+"/"+positionData.positionID} title={'Open '+positionData?.title+' Position In New Tab'}>
              <div className='positionTileTitle hoverUnderline inlineBlock'>{positionData?.title}</div>
            </Link>
          }
        </div>
        <div>
          <Link to={"/"+positionData?.companyID}  title={'Open '+positionData?.company+' Company In New Tab'}> 
            <div className='positionCompanyName hoverUnderline inlineBlock'>{positionData?.company}</div>
          </Link>
        </div>
        {/* <div className='positionDescription'>{"Project pay rate: "+positionData?.payRate}</div> */}
        <div className='positionDescription'>{positionData?.description}</div>
        <div className='positionTags'>
            {positionData?.tags?.map(tag=>(
                <div className='positionTag'>{tag}</div>
            ))}
        </div>
        
    </div>
  )
}
PositionTile.defaultProps = {
  positionClickFunction: (data) => {console.log("Clicked position tile with no click function."); console.log(data);},

}
export default PositionTile