import React from 'react'
import StarRating from './StarRating/StarRating'

function Review({reviewData}) {
  return (
    <div className='borderBox reviewBox'>
        <StarRating rating={reviewData?.rating}></StarRating>
        <div className='reviewComment'>
            {reviewData?.comment}
        </div>
        <div className='reviewComment'>
            {reviewData?.reviewer}
        </div>
    </div>
  )
}

export default Review