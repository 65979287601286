import React from 'react'
import CircleButton from './CircleButton'
import jobSearchIcon from "../../../Images/jobSearchIcon.png"
import employerIcon from "../../../Images/employerIcon.png"
import hrIcon from "../../../Images/hrIcon.png"
import backgroundCheckIcon from "../../../Images/backgroundCheckIcon.png"

function CirclesSection() {
  return (
    <div className='colorBox'>
        <div className='pageInnerWidth'>
        <div className=''>
            <div className='inlineBlock'>
              <CircleButton title={"For Employers"} imageUrl={employerIcon} link={"/account"}></CircleButton>
              <CircleButton title={"For Job Seekers"} imageUrl={jobSearchIcon} link={"/all-positions"}></CircleButton>
            </div>
            <div className='inlineBlock'>
              <CircleButton title={"HR Administration"} imageUrl={hrIcon} link={"/account"}></CircleButton>
              <CircleButton title={"Background Checks"} imageUrl={backgroundCheckIcon} link={"/background-checks"}></CircleButton>
            </div>
        </div>
        </div>
    </div>
  )
}

export default CirclesSection