import React, { useEffect, useState } from 'react'
import Tile from './Tile'
import placeholderImgUrl from "../../Images/bannerImageBeforeVideo.jpg"

function FourTiles({key, title}) {

    let tileData = {
        key1: [
            {
                title: "Tile 1",
                description: null,
                imageUrl: placeholderImgUrl,
            },
            {
                title: "Tile 2",
                description: null,
                imageUrl: placeholderImgUrl,
            },
            {
                title: "Tile 3",
                description: null,
                imageUrl: placeholderImgUrl,
            },
            {
                title: "Tile 4",
                description: null,
                imageUrl: placeholderImgUrl,
            },
        ]
    }

    const [tilesData, setTilesData] = useState([])
    useEffect(()=>{
        setTilesData(tileData[key] || tileData["key1"])
    },[])

  return (
    <div className="width100">
        <div className='title'>{title}</div>
        <div>
            {tilesData.map((tileData, index) => (
                <Tile
                    title={tileData?.title}
                    title2={tileData?.title2}
                    description={tileData?.description}
                    imageUrl={tileData?.imageUrl}
                    customClass={((index !== 0) && (index % 3 == 0)) ? " sometimesHiddenTile ":" "}
                ></Tile>
            ))}
        </div>
        {/* <div>
            <Tile
                // description={"hello"}
                ></Tile>
            <Tile 
                // description={true}
                ></Tile>
            <Tile 
                // description={"hello"}
                ></Tile>
            <Tile 
                // description={"hello"}
                customClass={"sometimesHiddenTile"}
            ></Tile>
        </div> */}
    </div>
  )
}

FourTiles.defaultProps = {
    title: "Four Tiles",
    key: "key1",
}

export default FourTiles