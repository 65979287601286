import React from 'react'
import { Link } from 'react-router-dom'

function CircleButton({imageUrl, title, link}) {
  return (
    <div className='circleButton'>
        <Link to={link}>
            <div className='circleButtonImg'>
                <img src={imageUrl}></img>
            </div>
            <div className='title title2 '>{title}</div>
        </Link>
    </div>
  )
}
CircleButton.defaultProps = {
    imageUrl: "",
    title: "Title", 
    link: "/",
}
export default CircleButton