import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './Components/Home/Home';
import TopNav from './Components/Nav/TopNav';
import BottomNav from './Components/Nav/BottomNav';
import CompanyPositions from './Components/Positions/CompanyPositions';
import PositionDisplayPage from './Components/Positions/PositionDisplayPage';
import CompanyOverview from './Components/CompanyOverview/CompanyOverview';
import AuthPage from './Components/Auth/AuthPage';
import BackgroundChecksLanding from './Components/Home/BackgroundChecksLanding';
import BackgroundCheckApply from './Components/Careers/BackgroundCheckApply';
import { isMobile } from 'react-device-detect';

function App() {
  /*

    Live Site: https://integrity-staffing.co/
    Live Dev Site: https://integrity-staffing.onrender.com/
    Email: hr@integrity-staffing.co
    https://www.linkedin.com/hiring/jobs/3784059082/detail/?trk=talent_jobPost_optimize
    Our mission is securing talent for businesses and fostering the growth of careers by connecting and empowering.
    
    TODO Soon
    Test application process in private tab
    check the admin site
    post links

    TODO Future:
    find which info is needed (by applying yourself)
    update apply areas

    Whalley TODO:
    hash router
    test apply area
    login area works and goes to dashbgoard with downloadale cert
    mobile view

    ========================================
    For components project (not really what this is anymore)

    startup CSS

    banner
    tile (multiple types)
    topnav (with mobile version that can go on top or bottom based on parameter)
    bottom nav
    collapasable box (like year in whalley program detail)
    collapasable section  (like course descriptoin in whalley program detail)
    manu window
    auth window
    image homepage section
    text homepage section
    inline topnav from ocala auto
    services page
    reviews

    search bar that filters objects
    filters bar
    chat 
      chats window
      chat box
      multi chat box
    image uploader
    application form
    positions page connected to application form
    faw section
    yourube box
    info box like in extended info on freefer profile
    image viewer window
    images box
    back to top bar


    pages
    events page
    positions
    apply
    contact
    research / articles

    firebase setup
    images


    easy-apply

    homepages:
    will talk about what it is
    
    /companyName 
    will have say layout as the homepage but with button to view open positions, or show them there

    /companyName/positionName
    displays the application for that position

    companyName/backgroundCheck/name
    shows an apply area with special inputs that the user will input for completing a bg check 


    examples:

    homepage:
    https://fadv.com/solutions/employment-background-checks/

    bg check page
    https://www.backgroundchecks.com/
    https://checkr.com/signup

    job postings
    https://www.indeed.com/?from=gnav-homepage
    https://www.glassdoor.com/Job/sales-representative-jobs-SRCH_KO0,20.htm

    from homepage
    banner 
    image text lr alternating
    orange box 
    puple banner
    circle images with links

    https://www.backgroundchecks.com/
    pricing squares
    top section squares

    whalley
    topnav
    bottom nav
    tile
    apply 

    positions styling from other job sites 

    in mobile vew can have all of them in a column covering the full width
    when user clicks one it displays the information and apply box

    in desktop the column is on the left and the position data is on the right with the apply box
    if the screen gets small enough it displays like in mobile

    good example:
    https://www.google.com/search?q=jobs&sca_esv=587889185&sxsrf=AM9HkKnUzhtRTk3TgNt9v1eFs-D6cN0I2Q:1701743203067&source=hp&ei=Y4puZc43jb7Q8Q-65LPICA&iflsig=AO6bgOgAAAAAZW6Yc5DTGoWX873s-I5iKXQi844hGgWf&uact=5&oq=jobs&gs_lp=Egdnd3Mtd2l6IgRqb2JzMgsQABiABBixAxiDATIIEAAYgAQYkgMyCxAAGIAEGIoFGJIDMg4QABiABBixAxiDARjJAzIIEAAYgAQYsQMyBRAAGIAEMgsQABiABBixAxiDATIFEAAYgAQyBRAAGIAEMgUQABiABEjzGFAAWLwXcAZ4AJABAJgBxAGgAcgHqgEDOC4yuAEDyAEA-AEBqAIKwgIFEC4YgATCAg0QABiABBixAxiDARgKwgIHECMY6gIYJ8ICChAjGIAEGIoFGCfCAgQQIxgnwgILEAAYgAQYigUYkQLCAg4QLhiABBiKBRixAxiDAcICERAuGIAEGLEDGIMBGMcBGNEDwgILEC4YgAQYsQMYgwHCAgsQLhiABBjHARivAcICBxAAGIAEGAo&sclient=gws-wiz&ibp=htl;jobs&sa=X&ved=2ahUKEwj_yvu6n_eCAxXXODQIHb0TCmoQutcGKAF6BAgUEAY#htivrt=jobs&htidocid=K4VCT6doGfe9kBF8AAAAAA%3D%3D&fpstate=tldetail

    example dru screen
    https://veriscreen.net/solutions/drug-screening/

  */

  return (
    <div className={"App" + (isMobile ? " AppMobile":"")}>
        <TopNav></TopNav>
        <div className='topnavOffset'>
          <Routes>
            
            {/* Page Links */}
            <Route path="/" element={<Home></Home>}/>
            <Route path="/all-positions" element={<CompanyPositions></CompanyPositions>}/>
            <Route path="/:companyID" element={<CompanyOverview></CompanyOverview>}/>
            <Route path="/background-checks" element={<BackgroundChecksLanding></BackgroundChecksLanding>}/>
            <Route path="/account" element={<AuthPage></AuthPage>}/>
            
            {/* Background Check Links */}
            <Route path="/background-check" element={<BackgroundCheckApply></BackgroundCheckApply>}/>
            <Route path="/background-check/:applicantName" element={<BackgroundCheckApply></BackgroundCheckApply>}/>
            <Route path="/:companyID/background-check" element={<BackgroundCheckApply></BackgroundCheckApply>}/>
            <Route path="/:companyID/background-check/:userName" element={<BackgroundCheckApply></BackgroundCheckApply>}/>
            
            {/* Position Links */}
            <Route path="/:companyID/:positionID" element={<PositionDisplayPage></PositionDisplayPage>}/>
            <Route path="/:companyID/:positionID/:tabName" element={<PositionDisplayPage></PositionDisplayPage>}/>
          
          </Routes>
          <BottomNav></BottomNav>
        </div>
    </div>
  );
}

export default App;
