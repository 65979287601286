import React from 'react'
import { Link } from 'react-router-dom'
import jobSearchLaptop from "../../../Images/jobSearchLaptop.webp"
import { isMobile } from 'react-device-detect'

function BackgroundBox({title, title2, description, link, linkText}) {
  return (
    <div className='applicationSection2'>
        <div className='applicationSection2PageWidth'>
            <img src={jobSearchLaptop}></img>
            <div className={'applicationSection2Text ' + (isMobile ? " applicationSection2TextMobile":"")}>
                <div className='applicationSection2Title'>{title}</div>
                <div className='applicationSection2Title2'>
                    <div>
                        {title2} 
                    </div>
                    {/* <div>
                        Take the next step to save your spot at Whalley.

                    </div> */}
                    <div className='applicationSection2Title3'>
                        {description}
                    </div>
                    <div className='applicationSection2ButtonConteiner'>
                        <Link to={link}>
                            <button className='applicationButton2'>{linkText}</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>

  )
}

BackgroundBox.defaultProps = {
    title: "OPEN POSITIONS DIRECTORY",
    title2: "Looking for a career?",
    linkText: "View Open Positions",
    link: "/all-positions",
    description: "View our list of open positions and apply directly on the listing. ",
}

export default BackgroundBox