import React from 'react'
import "./Home.css"
import Banner from './Components/Banner'
import ImageTextSection from './Components/ImageTextSection'
import FourTiles from '../Tile/FourTiles'
import BackgroundBox from './Components/BackgroundBox'
import CircleButton from './Components/CircleButton'
import CirclesSection from './Components/CirclesSection'

import bgCheckCrim from "../../Images/bgCheckCrim.png"
import bgInternational from "../../Images/bgInternational.jpg"
import bgResume from "../../Images/bgResume.jpg"
import bgEduVerify from "../../Images/bgEduVerify.jpg"
import bgCredit from "../../Images/bgCredit.png"
import bgCheckBanner from "../../Images/bgCheckBannerOverlayed.jpg"
import bgNoDrugs from "../../Images/bgNoDrugs.png"


function BackgroundChecksLanding({key}) {

  let sectionsData = [
    {
      "title": "Comprehensive Criminal Background Checks",
      "description": "Our Criminal Background Check service provides an in-depth review of an individual's criminal history, ensuring your organization can make informed decisions. We delve into national, state, and local criminal databases to provide a complete picture, including felony and misdemeanor records, sex offender status, and any warrants or ongoing legal proceedings. Our checks are thorough, up-to-date, and compliant with legal standards, helping you mitigate risks effectively.",
      "imageUrl": bgCheckCrim
    },
    {
      "title": "Education Verification and Credential Checks",
      "description": "Our Education Verification service rigorously verifies the academic credentials of potential hires. We confirm degrees, certifications, and educational history directly with institutions to ensure accuracy. This service helps you validate the qualifications of your candidates, ensuring they possess the education they claim. Our process is thorough and respects privacy laws, providing you with reliable and pertinent information.",
      "imageUrl": bgEduVerify,
    },
    {
      "title": "Employment History and Reference Checks",
      "description": "We provide comprehensive Employment History and Reference Checks to confirm a candidate's professional background. This includes verifying past employment, job titles, responsibilities, and durations, as well as contacting references to gather insights into the candidate's performance, reliability, and work ethic. This service is essential in building a complete picture of a candidate's professional background and suitability for your organization.",
      "imageUrl": bgResume,
    },
    {
      "title": "Drug Testing and Health Screening",
      "description": "Our Drug Testing and Health Screening services are designed to ensure workplace safety and compliance with health standards. We offer a variety of drug testing methods and comprehensive health screenings, tailored to your organization's needs. These tests help in maintaining a safe and productive work environment, ensuring that your team is healthy and capable of performing their duties effectively.",
      "imageUrl": bgNoDrugs,
    },
    {
      "title": "International Background Checks",
      "description": "Our International Background Checks extend our screening services globally, allowing you to vet international candidates effectively. This service covers criminal history, employment verification, and educational credentials across multiple countries. We navigate different legal and cultural landscapes to bring you accurate and compliant international screening results, essential for global businesses.",
      "imageUrl": bgInternational,
    },
    {
      "title": "Credit and Financial Background Checks",
      "description": "Our Credit and Financial Background Checks are crucial for positions involving financial responsibilities. We provide detailed reports on a candidate's credit history, financial stability, and any history of bankruptcies or financial misdemeanors. This service is vital for assessing the reliability and integrity of candidates for financially sensitive roles, helping you protect your organization's assets.",
      "imageUrl": bgCredit,
    }
  ]
  
  
    return (
      <div>
        <Banner
            title={"Background Screening Services"}
            description={"Our service offers a streamlined, comprehensive background check process, covering everything from criminal history to credit assessments for informed hiring decisions."}
            buttonText={"For Employers"}
            buttonLink={"/account"}
            buttonText2={"For Employees"}
            buttonLink2={"/all-positions"}
            imageUrl={bgCheckBanner}
          ></Banner>

        <div className='pageInnerWidth'>
          {sectionsData.map((data, index) => (
            <ImageTextSection 
              imageRight={index%2 == 0}
              title={data?.title}
              description={data?.description}
              imageUrl={data?.imageUrl}
            ></ImageTextSection>
          ))}

          {/* <FourTiles></FourTiles> */}

        </div>
        <div className='pageSection'>

          <CirclesSection></CirclesSection>

          <BackgroundBox></BackgroundBox>
        
        </div>
    </div>
  )
}

export default BackgroundChecksLanding